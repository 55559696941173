<!-- System: STA
    Purpose: Real time monitor shows modal
        live monitoring of member
-->
<template>
  <!-- Real Time Monitor Modal -->
  <v-dialog
    max-width="800"
    class="pa-0 white-bg"
    v-model="realTimeMonitorModal"
    @click:outside="modal_hide"
    @keydown.esc="modal_hide"
  >
    <!-- Real Time Monitor Card -->
    <v-card>
      <v-card-title class="headline white lighten-2 justify-center">
        Real Time Monitor
        <v-spacer></v-spacer>
        <v-btn icon @click="modal_hide()" id="close-btn">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <!-- Images Of Member Real Time -->
      <div v-show="!!instant_capture_src">
        <v-img
          :src="instant_capture_src"
          alt="Waiting for Signals"
          class="img-fluid w-100"
          width="800"
        />
      </div>
      <!-- Loader If Image Soucre Is Empty-->
      <Loader v-if="!instant_capture_src" />
    </v-card>
  </v-dialog>
</template>

<script>
import moment from "moment";

export default {
  name: "MemberRealTimeMonitor",
  components: {
    Loader: () => import("@/components/includes/CreateVideoLoader"),
  },
  data() {
    return {
      instant_capture_src: null,
      user_id: null,
      start_time: null,
      end_time: null,
      realTimeMonitorModal: false,
      stopCondition: true,
      timeout: "",
    };
  },
  mounted() {
    /**
     * Custom event to update image source
     */
    this.$root.$on("real_time_monitor", (data, userId = null) => {
      if (this.user_id !== null && this.user_id === userId) {
        if (this.stopCondition == true) {
          this.modal_show();
        }
        this.instant_capture_src = data;
      } else {
        this.$socket.emit("stop_real_time_monitor", userId);
      }
    });
    this.$root.$on("real_send_member_id", (data) => {
      this.user_id = data;
      this.realTimeMonitorModal = true;
      setTimeout(() => {
        if (this.instant_capture_src == null) {
          if (this.realTimeMonitorModal == true) {
            (this.realTimeMonitorModal = false),
              this.$root.$emit("snack_bar", {
                show: true,
                message: "Something went wrong please try again",
                snackbarColor: "red",
              });
          }
        }
      }, 20000);
    });
  },
  methods: {
    /**
     * This function is resposible for
     * hide modal and send log to server
     * and clear previous data
     */
    modal_hide() {
      if (this.user_id !== null) {
        this.$socket.emit("stop_real_time_monitor", this.user_id);
        this.end_time = new Date();
        this.realTimeMonitorModal = false;
        let total_time = this.totalTimeMonitring(
          this.start_time,
          this.end_time
        );
        let data = {
          company_id: this.$route.params.id,
          start_time: moment(String(this.start_time)).format(
            "YYYY/MM/DD hh:mm:ss"
          ),
          end_time: moment(String(this.end_time)).format("YYYY/MM/DD hh:mm:ss"),
          member_id: this.user_id,
          total_time_monitoring: total_time,
        };
        this.user_id = null;
        this.instant_capture_src = null;
        this.$store.dispatch("custom/custom_log_real_time_monitor", data);
        this.stopCondition = true;
        clearTimeout(this.timeout);
      }
    },
    /**
     * This function is resposible for
     * total monitoring time
     */
    totalTimeMonitring(startDate, endTime) {
      var diff = Math.abs(startDate - endTime);
      var minutes = Math.floor(diff / 1000 / 60);
      var date1 = startDate;
      var date2 = endTime;
      var diff = date2.getTime() - date1.getTime();
      var msec = diff;
      var hh = Math.floor(msec / 1000 / 60 / 60);
      msec -= hh * 1000 * 60 * 60;
      var mm = Math.floor(msec / 1000 / 60);
      msec -= mm * 1000 * 60;
      var ss = Math.floor(msec / 1000);
      msec -= ss * 1000;
      let total_time = hh + ":" + mm + ":" + ss;
      return total_time;
    },
    /**
     * This function is resposible for
     * start time and clear source
     * Close modal after 60 sec
     */
    modal_show() {
      this.stopCondition = false;
      this.start_time = new Date();
      this.instant_capture_src = null;
      this.timeout = setTimeout(() => {
        if (document.getElementById("close-btn")) {
          document.getElementById("close-btn").click();
        }
      }, 60000);
    },
  },
};
</script>

<style scoped>
</style>
